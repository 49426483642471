import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { useEffect } from 'react';

import Stack from '@mui/material/Stack';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';

export default function TurtleAppBar() {

useEffect(()=>{

},[]);




  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed">
        <Toolbar>
          {/*<IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>*/}
          <Typography variant="h6" component="div" sx={{ width:'200px', fontWeight:'bold' }}>
            Turtle
          </Typography>

     


        
      <Button size={"small"} style={{color:'#ffffff'}}>Map</Button>
      <Button size={"small"} style={{color:'#a0a0a0'}}>Ride</Button>
 

          <Button size={"small"} style={{color:'#ffffff',marginLeft:'auto',}}>Admin</Button>
        </Toolbar>
      </AppBar>
    </Box>
  );
}